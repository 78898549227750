import { Container } from '@components'
import React from 'react'
import { SectionSeperator } from '../layout'
import DiscoverCard from './discover-card'

const DiscoverSection = () => {
    return (
        <SectionSeperator>
            <Container>
                <DiscoverCard
                    imgUrl='/images/home/3.png'
                    title='CREATING REAL CHANGE'
                    subtitle='WE’RE BUILDING SOMETHING BIG'
                    description='To make a real impact on the issue of food waste, 
                        we need to work with public affairs, education, 
                        and hand in hand with households and businesses. 
                        Discover our many actions here.'
                    btn='Discover More' />
                <DiscoverCard
                    className="mt-14"
                    imgUrl='/images/home/4.png'
                    title='A BUSINESS NO-BRAINER'
                    subtitle='YOU’RE A (BIG) PART OF THIS!'
                    description='We love welcoming new partners to the fight against 
                        food waste, and whatever your business, we can help 
                        you recuperate costs and lower your footprint - it’s 
                        100% good for you and the planet.'
                    btn='Sign up your Business'
                    reverse
                />
            </Container>
        </SectionSeperator>
    )
}

export default DiscoverSection