import { Box, Title, Paragraph, Button } from '@components';
import Image from 'next/image';
import React from 'react'

type Props = {
    imgUrl: string;
    title: string;
    subtitle?: string | undefined;
    description?: string;
    btn: string;
    className?: string | undefined;
    reverse?: boolean | undefined
}

const DiscoverCard = ({ imgUrl, title, subtitle, description, btn, reverse, className = '' }: Props) => {

    return (
        <Box className={`${className} flex ${reverse ? 'flex-row' : 'flex-row-reverse'} items-center justify-center rounded-2xl`} >
            <Box>
                <Image className='rounded-2xl' layout='fixed' src={imgUrl} alt="discover-img" objectFit='cover' width={566.67} height={486} />
            </Box>
            <Box className='flex items-start flex-col px-16'>
                <Box className='flex flex-col'>
                    {title && <Title tag='h4' className='font-bold text-3xl leading-[54px] text-[#1C6162]'>{title}</Title>}
                    {subtitle && <Title tag='h5' className='font-medium text-lg leading-7 text-[#33BC92]'>{subtitle}</Title>}
                </Box>
                <Box className='mt-4'>
                    {description && <Paragraph className='font-medium pb-6 text-base leading-7 text-[#69798D]'>{description}</Paragraph>}
                    <Button variant='secondary' className='font-medium'>{btn}</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default DiscoverCard