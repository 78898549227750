import { Box } from '@components'
import Image from 'next/image'
import React from 'react'

type Props = {
  imgUrl: string
  children: React.ReactNode
  className?: string | undefined
  relativeView?: boolean | undefined
}

const DefaultLayout = ({ imgUrl, children, relativeView, className = '' }: Props) => {
  return (
    <Box className={`${className} min-h-[calc(100vh-100px)]`}>
      <Box className={`${relativeView ? 'h-[600px]' : 'h-[480px]'} relative z-10`}>
        <Box className='absolute top-0 left-0 right-0 bottom-0 z-10'>
          <Box className="relative h-[600px]">
            <Image src={imgUrl} alt={imgUrl} priority layout="fill" objectFit='cover' />
          </Box>
        </Box>
      </Box>
      <Box className='z-20 relative'>
        {children}
      </Box>
    </Box>
  )
}

export default DefaultLayout